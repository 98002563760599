import { axios } from "@spotswap/shared";
import {
  createSlice,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";
import { requestClient } from "../environment";

export type MyState = {
  email: string;
  displayName: string;
  avatarUrl?: string;
};

export interface AuthState {
  // Indicates whether session was checked after page reload
  checked: boolean;
  // The authentication (Bearer) token itself
  token?: string;
  error?: Error;
  my?: MyState;
}

const initialState: AuthState = {
  checked: false,
};

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authLogin(state: Draft<AuthState>, action: PayloadAction<string>) {
      console.debug("[redux:auth] Authorization token has been received [authLogin]");
      state.checked = true;
      state.token = action.payload;
      axios.defaults.headers.common.Authorization = `Bearer ${action.payload}`;
      requestClient.setHeader("Authorization", `Bearer ${action.payload}`);
    },
    authLogout(state: Draft<AuthState>) {
      console.debug("[redux:auth] Authorization token has been reset [authLogout]");
      state.checked = true;
      requestClient.setHeader("Authorization", "");
      delete axios.defaults.headers.common.Authorization;
      delete state.token;
    },
    authError(state: Draft<AuthState>, action: PayloadAction<Error>) {
      console.debug("[redux:auth] Authorization error [authError]", action.payload);
      state.error = action.payload;
    },
    authInit(state: Draft<AuthState>, action: PayloadAction<MyState>) {
      console.debug("[redux:auth] Initialization data received [authInit]", action.payload);
      delete state.error;
      state.my = action.payload;
    },
  },
});

export const {
  authLogin,
  authLogout,
  authError,
  authInit,
} = auth.actions;

export default auth.reducer;
