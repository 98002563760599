import React, { Suspense } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from "react-router-dom";
import loader from "./loader";

const Layout = React.lazy(() => import("src/components/Layout"));

const Login = React.lazy(() => import("src/pages/Login"));
const Logout = React.lazy(() => import("src/pages/Logout"));

const PasswordReset = React.lazy(() => import("src/pages/PasswordReset"));

const HomeRoot = React.lazy(() => import("src/pages/Home"));
const PartnersRoot = React.lazy(() => import("src/pages/Partners"));
const UsersRoot = React.lazy(() => import("src/pages/Users"));

const AccountRoot = React.lazy(() => import("src/pages/Account"));
const AccountProfile = React.lazy(() => import("src/pages/Account/Profile"));
const AccountSecurity = React.lazy(() => import("src/pages/Account/Security"));

const SomethingWentWrong = React.lazy(() => import("src/pages/SomethingWentWrong"));

const AppRouter = () => {
  const suspended = (children: React.ReactNode, skeleton?: React.ReactNode) => (
    <Suspense fallback={skeleton}>
      {children}
    </Suspense>
  );

  const router = createBrowserRouter([{
    errorElement: suspended(<SomethingWentWrong />),
    children: [
      {
        path: "login",
        element: suspended(<Login />),
      },
      {
        path: "logout",
        element: suspended(<Logout />),
      },
      {
        path: "password-reset",
        element: suspended(<PasswordReset />),
      },
      {
        element: suspended(<Layout />),
        loader,
        children: [ {
          path: "/",
          element: suspended(<HomeRoot />),
        }, {
          path: "partners",
          element: suspended(<PartnersRoot />),
        }, {
          path: "users",
          element: suspended(<UsersRoot />),
        }, {
          path: "account",
          element: suspended(<AccountRoot />),
          children: [ {
            index: true,
            loader: () => redirect("/account/profile"),
          }, {
            path: "profile",
            element: suspended(<AccountProfile />),
            handle: {
              crumb: () => {
                return { selectedIndex: 0 };
              },
            },
          },
          {
            path: "security",
            element: suspended(<AccountSecurity />),
            handle: {
              crumb: () => {
                return { selectedIndex: 1 };
              },
            },
          },
          ],
        } ],
      } ],
  }]);

  return (<RouterProvider router={router} />);
};

export default AppRouter;
