import { configureStore } from "@reduxjs/toolkit";
import { theme } from "@spotswap/shared";
import environmentReducer from "./environment";
import authReducer from "./auth";
import notificationsReducer from "./notifications";

export const store = configureStore({
  reducer: {
    environment: environmentReducer,
    theme: theme.reducer,
    auth: authReducer,
    notifications: notificationsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;
