import { graphql } from "src/api/gql";
import { LoaderFunction, redirect } from "react-router-dom";
import { WebAuth } from "auth0-js";
import { store } from "src/redux/store";
import { authInit, authLogin } from "src/redux/auth";
import { requestClient } from "src/environment";

const ContextNode = graphql(`
  query Context {
    my: profile {
      displayName
      email {
        formatted (format: simple)
      }
      avatar {
        url
      }
    }
  }
`);

export const loader: LoaderFunction = async (args) => {
  const {
    auth,
    environment,
  } = store.getState();

  console.debug("[loader] Asserting the authorization token is present");

  if (!auth.token) {
    console.debug("[loader] Authorization token is not present, attempting to reacquire");

    const {
      config,
      redirectUri,
      scope,
    } = environment;

    if (!config) {
      throw Error("[loader] Environment configuration is not yet available");
    }

    const auth0 = new WebAuth({
      domain: config.auth.domain,
      clientID: config.auth.clientID,
      audience: config.auth.audience,
      redirectUri,
      scope,
    });

    const loggedIn = await new Promise((resolve) => {
      auth0.checkSession({
        responseType: "token",
      }, (_, x) => {
        if (x?.accessToken) {
          store.dispatch(authLogin(x.accessToken));
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });

    if (!loggedIn) {
      console.debug("[loader] Authorization token is not present, redirecting to login");
      return redirect("/login");
    }
  }

  console.debug("[loader] Asserting the admin context is present");

  if (!auth.my) {
    console.debug("[loader] Admin context is not present, attempting to reacquire");

    const response = await requestClient.request({
      document: ContextNode,
      variables: {},
      signal: args.request.signal,
    });

    store.dispatch(authInit({
      email: response.my.email.formatted,
      displayName: response.my.displayName,
      avatarUrl: response.my.avatar?.url,
    }));
  }

  return null;
};

export default loader;
