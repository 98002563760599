import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import {
  axios,
  EnvironmentConfig,
  EnvironmentState,
} from "@spotswap/shared";
import {
  createSlice,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";
import { requestClient } from "../environment";

const DEFAULT_DATADOG_SITE = "datadoghq.eu";

const initialState: EnvironmentState = {
  redirectUri: `${window.location.origin}/login`,
  realm: "SpotSwap-Admin",
  scope: "openid profile email admin",
};

export const environment = createSlice({
  name: "environment",
  initialState,
  reducers: {
    configure(state: Draft<EnvironmentState>, action: PayloadAction<EnvironmentConfig>) {
      console.debug("[redux:environment] Configuring environment with new parameters [configure]");

      const {
        logs,
        rum,
      } = action.payload;

      if (!logs?.token) {
        console.warn("No logs client token is configured");
      } else {
        datadogLogs.init({
          clientToken: logs.token,
          site: DEFAULT_DATADOG_SITE,
          service: window.origin,
          env: process.env.NODE_ENV,
          version: action.payload.version,
          forwardErrorsToLogs: true,
          sessionSampleRate: 100,
        });
      }

      if (!rum?.token) {
        console.warn("No RUM client token is configured");
      } else if (!rum?.appID) {
        console.warn("No RUM application ID is configured");
      } else {
        datadogRum.init({
          applicationId: rum.appID,
          clientToken: rum.token,
          site: DEFAULT_DATADOG_SITE,
          service: window.origin,
          env: process.env.NODE_ENV,
          version: action.payload.version,
          sessionSampleRate: 100,
          sessionReplaySampleRate: 100,
          trackResources: true,
          trackLongTasks: true,
          trackUserInteractions: true,
        });
      }

      state.config = action.payload;
      axios.defaults.baseURL = action.payload.api;
      requestClient.setEndpoint(`${action.payload.api}/graphql/admin`);
    },
  },
});

export const {
  configure,
} = environment.actions;

export default environment.reducer;
