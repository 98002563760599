import { ThemeProvider } from "styled-components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEnvironment } from "@spotswap/shared";
import GlobalStyles from "@spotswap/shared/GlobalStyles";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { configure } from "src/redux/environment";
import AppRouter from "./infrastructure/SpotSwapRouter";
import {NotificationsWidget} from "./widgets/NotificationsWidget";

const queryClient = new QueryClient();

const App = () => {

  const dispatch = useAppDispatch();

  useEnvironment({
    onLoaded: (x) => dispatch(configure(x)),
  });

  const {
    config,
  } = useAppSelector((x) => x.environment);

  const appTheme = useAppSelector((x) => x.theme);

  return config ? (
    <>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={appTheme}>
          <AppRouter />
          <NotificationsWidget count={3} timer={6000} />
        </ThemeProvider>
      </QueryClientProvider>
    </>
  ) : (<GlobalStyles />);
};

export default App;
